import './Line.sass'

const Line = (props) => {
    let direction = '';
    if (props.left) direction = 'justify-content-end';
    if (props.center) direction = 'justify-content-center';

    return (
        <div className={'line__container d-flex ' + direction + ' ' + props.sizeClass}>
            <div className='line'></div>
        </div>
    );
};

export default Line;