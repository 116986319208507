import {useTranslation} from "react-i18next";

import Line from "../Line";

import './HeroSection.sass';

const HeroSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='hero' id='start' ref={props.sectionRef}>
            <div className='back'>
                <div>
                    <span>Advanced marketing</span>
                    <span className='accent'>marketing Marketing</span>
                    <span>amark.pro amark.pro</span>
                </div>
            </div>
            <div className='front'>
                <div className='hero__container'>
                    <h1 className='hero__header'>
                        <span style={{letterSpacing: '-0.05em'}}>{t('hero.front.line1')}</span>
                        Advanced <br />
                        Marketing.
                    </h1>
                    <Line left sizeClass='line-size' />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;