import './Input.sass';

const Input = (props) => {
    let errorClass = '';
    if (props.isError) errorClass = 'error';

    return (
        <div className='input-container'>
            <label htmlFor={props.name}>{props.label}</label>
            <input
                className={errorClass}
                type={props.type}
                name={props.name}
                id={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
};

export default Input;