const Ipad = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="243" height="317" viewBox="0 0 243 317" fill="none">
            <g filter="url(#filter0_b_1091_639)">
                <rect width="242.002" height="317" rx="14" fill="#888888" fillOpacity="0.17" />
                <rect x="0.5" y="0.5" width="241.002" height="316" rx="13.5" stroke="#A3A3A3" strokeOpacity="0.4"/>
            </g>
            <g filter="url(#filter1_b_1091_639)">
                <rect x="7.73169" y="10.0513" width="226.656" height="296.898" rx="8" fill="#888888" fillOpacity="0.17" />
                <rect x="8.23169" y="10.5513" width="225.656" height="295.898" rx="7.5" stroke="#A3A3A3" strokeOpacity="0.4"/>
            </g>
            <circle cx="121.388" cy="5.41206" r="1.54634" fill="#C4C4C4"/>
            <defs>
                <filter id="filter0_b_1091_639" x="-24" y="-24" width="290.002" height="365" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="12"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1091_639"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1091_639" result="shape"/>
                </filter>
                <filter id="filter1_b_1091_639" x="-16.2683" y="-13.9487" width="274.656" height="344.897" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="12"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1091_639"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1091_639" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default Ipad;