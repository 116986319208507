import {useTranslation} from "react-i18next";
import {copyrightYear} from "../../helpers/CopyrightYear";
import {NavLink as NavLinkTo, NavLink} from "react-router-dom";

import Container from "../UI/Container";
import LogoLink from "../UI/other/LogoLink";

import TelegramHollowLogo from "../../assets/icons/TelegramHollowLogo";
import InstagramHollowLogo from "../../assets/icons/InstagramHollowLogo";
import FacebookHollowLogo from "../../assets/icons/FacebookHollowLogo";
import BehanceHollowLogo from "../../assets/icons/BehanceHollowLogo";
import './Footer.sass';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer>
            <Container>
                <div className='footer__grid'>
                    <div>
                        <h2><NavLinkTo to={'/'}>amark.pro</NavLinkTo></h2>
                        <p className='footer__description'>{t("footer.description")}</p>
                        <div className='footer__social-grid'>
                            <LogoLink href="https://t.me/amark_pro" width='19px' height='19px' icon={<TelegramHollowLogo color='#fff' />} />
                            <LogoLink href="https://www.instagram.com/amark.pro/" width='19px' height='19px' icon={<InstagramHollowLogo color='#fff' />} />
                            <LogoLink href="https://www.facebook.com/amark.pro" width='19px' height='19px' icon={<FacebookHollowLogo color='#fff' />} />
                            <LogoLink href="https://www.behance.net/maratdegtear" width='19px' height='19px' icon={<BehanceHollowLogo color='#fff' />} />
                        </div>
                        <p><a className='link' href='mailto:m.advancedmarketing@gmail.com'>m.advancedmarketing@gmail.com</a></p>
                    </div>
                    <div />
                    <div>
                        <h2>{t("footer.nav.title")}</h2>
                        <ul type='none'>
                            <li><NavLink to={'/policy'}>{t("footer.nav.terms")}</NavLink></li>
                            <li><NavLink to={'/policy#cookies'}>{t("footer.nav.cookies")}</NavLink></li>
                        </ul>
                    </div>
                </div>
                <p className='text-center footer__copyright'>© All rights reserved. Copyright {copyrightYear(2022)}</p>
            </Container>
        </footer>
    );
};

export default Footer;