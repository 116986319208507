import './LogoLink.sass';

const LogoLink = (props) => {
    const style = {
        width: props.width,
        height: props.height
    };

    return (
        <div className='logo-link' style={style}>
            <a href={props.href} target='_blank' rel='noreferrer'>{props.icon}</a>
        </div>
    );
};

export default LogoLink;