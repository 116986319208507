import {Fragment, useState} from "react";
import { useTranslation } from 'react-i18next';
import { NavLink as NavLinkTo } from "react-router-dom";

import Container from "../../UI/Container";
import NavLink from "./NavLink";
import Button from "../../UI/Buttons/Button";
import LogoLink from "../../UI/other/LogoLink";

import AmarkLogo from "../../../assets/icons/AmarkLogo";
import TelegramLogo from "../../../assets/icons/TelegramLogo";
import LinkedInLogo from "../../../assets/icons/LinkedInLogo";
import './Header.sass';

const Header = (props) => {
    const { t } = useTranslation();
    const [navbarIsOpened, setNavbarIsOpened] = useState(false);

    const toggleNavbarHandler = () => {
        setNavbarIsOpened(prevState => !prevState);
    };

    const navbarClass = navbarIsOpened ? 'show' : '';

    let home = (
        <NavLinkTo
            to={'/'}
            className={({ isActive }) =>
                isActive ? "nav-link active" : "nav-link"
            }>
            home
        </NavLinkTo>
    );
    if (props.home) {
        home = (
            <Fragment>
                <NavLink linkText={t('header.home')} id='start' onClick={toggleNavbarHandler} spyActive={props.visible.hero} />
                <NavLink linkText={t('header.services')} id='whatWeDo' onClick={toggleNavbarHandler} spyActive={props.visible.whatWeDo} />
                <NavLink linkText={t('header.projects')} id='ourProjects' onClick={toggleNavbarHandler} spyActive={props.visible.ourProjects} />
                <NavLink linkText={t('header.aboutUs')} id='aboutUs' onClick={toggleNavbarHandler} spyActive={props.visible.aboutUs || props.visible.whyUs} />
                <NavLink linkText={t('header.howWeWork')} id='howWeWork' onClick={toggleNavbarHandler} spyActive={props.visible.howWeWork || props.visible.happyClients} />
                <NavLink linkText={t('header.contactUs')} id='contactForm' onClick={toggleNavbarHandler} spyActive={props.visible.contactForm} />
            </Fragment>
        );
    }

    return (
        <Fragment>
            {/*<Container>*/}
            {/*    <LanguageChooser />*/}
            {/*</Container>*/}
            <header className="navbar">
                <Container>
                    <div className='navbar__container d-flex align-items-center flex-wrap'>
                        <div style={{width: '60px', height: '38px', marginRight: '40px'}}>
                            <NavLinkTo to={'/'}>
                                <AmarkLogo />
                            </NavLinkTo>
                        </div>

                        <Button className='tablet-only' onClick={toggleNavbarHandler} borderColor='rgba(41, 41, 41, 0)'>menu</Button>
                        <div className='d-flex justify-content-end navbar__links-container'>
                            <nav className={'d-flex align-items-center flex-wrap ' + navbarClass}>
                                {home}
                                {/*<NavLinkTo*/}
                                {/*    to={'/newsroom'}*/}
                                {/*    className={({ isActive }) =>*/}
                                {/*        isActive ? "nav-link active" : "nav-link"*/}
                                {/*    }>*/}
                                {/*    newsroom*/}
                                {/*</NavLinkTo>*/}
                                <div className='d-flex align-items-center tablet-only'>
                                    <LogoLink href='https://t.me/amark_pro' width='24px' heigth='20px' icon={<TelegramLogo color='#00FEEF' />} />
                                    <LogoLink href='https://www.linkedin.com/company/amark-pro/' width='24px' heigth='24px' icon={<LinkedInLogo color='#00FEEF' />} />
                                </div>
                            </nav>
                            <div className='d-flex align-items-center desktop-only'>
                                <LogoLink href='https://t.me/amark_pro' width='24px' heigth='20px' icon={<TelegramLogo color='#00FEEF' />} />
                                <LogoLink href='https://www.linkedin.com/company/amark-pro/' width='24px' heigth='24px' icon={<LinkedInLogo color='#00FEEF' />} />
                            </div>
                        </div>
                    </div>
                </Container>
            </header>
        </Fragment>
    );
};

export default Header;