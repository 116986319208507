const sendEmail = async (name, email, message) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name, email, message
        }),
    };

    const response = await fetch(window.location.origin + '/api/MailSender.php', requestOptions);
    return await response.json();
};

export {sendEmail};