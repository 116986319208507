import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enFile from './locales/en/translation.json';

i18n
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: false,

        resources: {
            en: {
                translation: enFile
            }
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;