import {Fragment} from "react";

import Header from "../сomponents/section/Header/Header";
import PageHeader from "../сomponents/UI/header/PageHeader";

const Newsroom = () => {
    return (
        <Fragment>
            <Header home={false} />
            <main>
                <PageHeader>
                    explore first-class materials of tech and design.
                </PageHeader>
            </main>
        </Fragment>
    );
};

export default Newsroom;