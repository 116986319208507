import './ServiceBlock.sass';

const ServiceBlock = (props) => {
    let imageOrder = 0;
    if (props.imageRight) imageOrder = 1;

    return (
        <article className='service-block'>
            <div className='d-flex justify-content-center' style={{order: imageOrder}}>
                {props.image && <img src={props.image} alt='' /> }
                {props.video && (
                    <video autoPlay loop muted playsInline>
                        <source src={props.video} type="video/mp4"/>
                    </video>
                )}
            </div>
            <div className='d-flex align-items-center' style={{order: 0}}>
                <div>
                    <h1>{props.header}</h1>
                    <p>{props.description}</p>
                    <div className='service-block__hashtags'>
                        {props.children}
                    </div>
                </div>
            </div>
        </article>
    );
};

export default ServiceBlock;