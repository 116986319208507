import './SectionHeader.sass';

const SectionHeader = (props) => {
    return (
        <header className='section-header'>
            <h2>{props.header}</h2>
            <p>
                {props.children}
            </p>
        </header>
    );
};

export default SectionHeader;