import './PolicyBlock.sass';

const PolicyBlock = ({headerText, paragraphs}) => {
    const policies = headerText.map((header, index) => (
        <li className="policy-block__header"><h3>{index + 1}. {header}</h3>
            <ol>
                {paragraphs[index].map((par) => <li>{par}</li>)}
            </ol>
        </li>
    ));

    return (
        <ol className="policy-block">
            {policies}
        </ol>
    );
};

export default PolicyBlock;