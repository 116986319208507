import './Container.sass';

const Container = (props) => {
    const classes = props.className ?? '';
    let full = '';
    if (props.full) full = 'full';

    return (
        <div className={'container ' + full + ' ' + classes}>
            {props.children}
        </div>
    );
};

export default Container;