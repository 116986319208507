import {Fragment} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import ScrollToTop from "./сomponents/ScrollToTop";
import Home from "./pages/Home";
import Newsroom from "./pages/Newsroom";
import CookiesBlock from "./сomponents/UI/block/CookiesBlock";
import PolicyPage from "./pages/PolicyPage";
import Footer from "./сomponents/section/Footer";

function App() {
    const Layout = (component) => {
        return (
            <Fragment>
                <ScrollToTop />
                {component}
                <Footer />
                <CookiesBlock />
            </Fragment>
        )
    };

    const router = createBrowserRouter([
        {
            path: '/',
            element: Layout(<Home />)
        },
        {
            path: '/newsroom',
            element: Layout(<Newsroom />)
        },
        {
            path: '/policy',
            element: Layout(<PolicyPage />)
        }
    ]);

    return <RouterProvider router={router} />;
}

export default App;
