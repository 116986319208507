import AnchorLink from "react-anchor-link-smooth-scroll/lib/anchor-link";

import './NavLink.sass';

const NavLink = (props) => {
    let classes = 'nav-link';
    if (props.spyActive) classes += ' active';

    return (
        <AnchorLink href={'#' + props.id} offset={100} onClick={props.onClick} className={classes}>{props.linkText}</AnchorLink>
    );
};

export default NavLink;