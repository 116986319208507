import './ReviewCard.sass';

const ReviewCard = (props) => {
    let avatar;

    if (props.color) {
        const circleColor = {
            background: props.color
        };
        avatar = <div className='circle' style={circleColor} />;
    } else if (props.image)
    {
        avatar = <img src={props.image} alt='' className='w-100' />
    }

    return (
        <article className='review-card'>
            <div className='review-card__grid'>
                {avatar}
                <div className='review-card__content'>
                    <div className='info'>
                        <div>
                            <h5>{props.name}</h5>
                            <p>{props.occupation}</p>
                        </div>
                    </div>
                    <p>
                        {props.children}
                    </p>
                </div>
            </div>
        </article>
    );
};

export default ReviewCard;