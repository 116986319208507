import Spinner from "./Spinner";
import SuccessIcon from "../../assets/icons/SuccessIcon";

import './ProcessLoading.sass';

const ProcessLoading = (props) => {
    return (
        <div className='process-loading'>
            {props.done ? <SuccessIcon /> : <Spinner />}
        </div>
    );
};

export default ProcessLoading;