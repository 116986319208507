import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import ServiceBlock from "../UI/block/ServiceBlock";
import Ball from "../Ball";
import Hashtag from "../UI/other/Hashtag";

import './WhatWeDoSection.sass';
import WWDTopH from '../../assets/video/what-we-do-topH.mp4';
import WWDTopV from '../../assets/video/what-we-do-topV.mp4';
import WWDBottom1 from '../../assets/img/what-we-do-bottom1.png';
import WWDBottom2 from '../../assets/img/what-we-do-bottom2.png';
import service1 from '../../assets/video/service1.mp4';
import service2 from '../../assets/img/service2.png';
import service3 from '../../assets/img/service3.png';
import service4 from '../../assets/img/service4.png';

const WhatWeDoSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='what-we-do' id='whatWeDo' ref={props.sectionRef}>
            <Container className='p-relative'>
                <div className='what-we-do__header'>
                    <SectionHeader header={t("whatWeDo.header.title")}>
                        {t("whatWeDo.header.description")}
                    </SectionHeader>
                    <video autoPlay loop muted playsInline>
                        <source src={WWDTopV} type="video/mp4"/>
                    </video>
                    <video autoPlay loop muted playsInline>
                        <source src={WWDTopH} type="video/mp4"/>
                    </video>
                </div>

                <ServiceBlock
                    header={t("whatWeDo.services.service1.title")}
                    video={service1}
                    borderColor='#00FEEF'
                    description={t("whatWeDo.services.service1.description")}
                >
                    <Hashtag text={t("whatWeDo.services.service1.tag1")} />
                    <Hashtag text={t("whatWeDo.services.service1.tag2")} />
                    <Hashtag text={t("whatWeDo.services.service1.tag3")} />
                    <Hashtag text={t("whatWeDo.services.service1.tag4")} />
                    <Hashtag text={t("whatWeDo.services.service1.tag5")} />
                </ServiceBlock>
                <ServiceBlock
                    header={t("whatWeDo.services.service2.title")}
                    image={service2}
                    imageRight
                    borderColor='#D3329E'
                    description={t("whatWeDo.services.service2.description")}
                >
                    <Hashtag text={t("whatWeDo.services.service2.tag1")} />
                    <Hashtag text={t("whatWeDo.services.service2.tag2")} />
                    <Hashtag text={t("whatWeDo.services.service2.tag3")} />
                    <Hashtag text={t("whatWeDo.services.service2.tag4")} />
                    <Hashtag text={t("whatWeDo.services.service2.tag5")} />
                </ServiceBlock>

                <div className='what-we-do__images'>
                    <img src={WWDBottom1} alt='' />
                    <img src={WWDBottom2} alt='' />
                </div>

                <ServiceBlock
                    header={t("whatWeDo.services.service3.title")}
                    image={service3}
                    borderColor='#75ED57'
                    description={t("whatWeDo.services.service3.description")}
                >
                    <Hashtag text={t("whatWeDo.services.service3.tag1")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag2")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag3")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag4")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag5")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag6")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag7")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag8")} />
                    <Hashtag text={t("whatWeDo.services.service3.tag9")} />
                </ServiceBlock>
                <ServiceBlock
                    header={t("whatWeDo.services.service4.title")}
                    image={service4}
                    imageRight
                    borderColor='#D3C332'
                    description={t("whatWeDo.services.service4.description")}
                >
                    <Hashtag text={t("whatWeDo.services.service4.tag1")} />
                    <Hashtag text={t("whatWeDo.services.service4.tag2")} />
                    <Hashtag text={t("whatWeDo.services.service4.tag3")} />
                    <Hashtag text={t("whatWeDo.services.service4.tag4")} />
                </ServiceBlock>
            </Container>

            <Ball
                top='10%'
                left='-20%'
                color='rgba(0, 255, 194, 0.5)'
            />

            <Ball
                bottom='20px'
                left='0'
                color='rgba(32, 64, 231, 0.25)'
            />
        </section>
    );
};

export default WhatWeDoSection;