import {useState} from "react";

import './Button.sass';

const Button = (props) => {
    const [isHover, setIsHover] = useState(false);

    const glowColor = props.borderColor ? props.borderColor : '#e5e5e5'
    const style = {
        borderColor: props.disabled ? '#232323' : props.borderColor,
        boxShadow: isHover ? `0 0 10px 1px ${glowColor}` : 'none'
    };
    const classes = ' ' + props.className ?? '';

    let disabledClass = '';
    if (props.disabled) disabledClass = 'disabled';

    return (
        <button
            className={'button ' + disabledClass + classes}
            style={style}
            disabled={props.disabled}
            onClick={props.onClick}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            {props.children}
        </button>
    );
};

export default Button;