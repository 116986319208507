import {useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmail} from "../../helpers/Validator";
import useInput from "../../hooks/use-input";
import {sendEmail} from "../../api/Mail";

import SectionHeader from "../UI/header/SectionHeader";
import Container from "../UI/Container";
import Input from "../UI/form/Input";
import Textarea from "../UI/form/Textarea";
import Button from "../UI/Buttons/Button";
import ProcessLoading from "../UI/ProcessLoading";
import LogoLink from "../UI/other/LogoLink";
import Ball from "../Ball";

import TelegramLogo from "../../assets/icons/TelegramLogo";
import FacebookLogo from "../../assets/icons/FacebookLogo";
import InstagramLogo from "../../assets/icons/InstagramLogo";
import LinkedInLogo from "../../assets/icons/LinkedInLogo";
import TikTokLogo from "../../assets/icons/TikTokLogo";
import './ContactFormSection.sass';

const ContactFormSection = (props) => {
    const { t } = useTranslation();
    const [showProcessLoading, setShowProcessLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);

    const {
        value: name,
        isValid: enteredNameIsValid,
        hasError: nameInputHasError,
        reset: nameReset,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: email,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => value.trim() !== '' && isEmail(value));

    const {
        value: message,
        isValid: enteredMessageIsValid,
        hasError: messageInputHasError,
        reset: messageReset,
        valueChangeHandler: messageChangedHandler,
        inputBlurHandler: messageBlurHandler
    } = useInput(value => value.trim() !== '');

    const formIsValid = enteredNameIsValid && enteredEmailIsValid && enteredMessageIsValid;

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (formIsValid) {
            setShowProcessLoading(true);
            setIsDone(false);
            const response = await sendEmail(name, email, message);
            if (response.error === 'ok')
            {
                setIsDone(true);
                nameReset();
                emailReset();
                messageReset();
            }
            else
            {
                setShowProcessLoading(false);
                alert('Something went wrong! Please, try again later!');
            }
        }
    };

    return (
        <section className='contact-form' id='contactForm' ref={props.sectionRef}>
            <Container full className='p-relative'>
                <SectionHeader header={t("contactUs.header.title")}>
                    {t("contactUs.header.description")}
                </SectionHeader>
            </Container>
            <Container full className='p-relative'>
                <div className='section-grid'>
                    <div className='form'>
                        <form onSubmit={onSubmitHandler}>
                            <Input
                                type='text'
                                label={t("contactUs.inputs.name")}
                                name='name'
                                value={name}
                                isError={nameInputHasError}
                                onChange={nameChangedHandler}
                                onBlur={nameBlurHandler}
                            />
                            <Input
                                type='email'
                                label={t("contactUs.inputs.email")}
                                name='email'
                                value={email}
                                isError={emailInputHasError}
                                onChange={emailChangedHandler}
                                onBlur={emailBlurHandler}
                            />
                            <Textarea
                                label={t("contactUs.inputs.message")}
                                name='message'
                                value={message}
                                isError={messageInputHasError}
                                onChange={messageChangedHandler}
                                onBlur={messageBlurHandler}
                            />
                            <div className='margin d-flex align-items-center'>
                                <Button className='w-100' borderColor='#D3329E' disabled={!formIsValid}>{t("contactUs.inputs.button")}</Button>
                                {showProcessLoading && <ProcessLoading done={isDone} /> }
                            </div>
                        </form>
                    </div>

                    <div className='contacts d-flex justify-content-center align-items-center'>
                        <div>
                            <p>
                                <a href='mailto:cooperation@amark.pro'>cooperation@amark.pro</a>
                            </p>
                            <p>
                                <a className='text-underline' href='mailto:m.advancedmarketing@gmail.com'>m.advancedmarketing@gmail.com</a>
                            </p>
                            <div className='d-flex justify-content-center logos'>
                                <LogoLink href='https://www.facebook.com/amark.pro' width='36px' height='36px' icon={<FacebookLogo color='#fff' />} />
                                <LogoLink href='https://t.me/amark_pro' width='36px' height='34px' icon={<TelegramLogo color='#fff' />} />
                                <LogoLink href='https://www.instagram.com/amark.pro/' width='36px' height='36px' icon={<InstagramLogo color='#fff' />} />
                                <LogoLink href='https://www.linkedin.com/company/amark-pro/' width='36px' height='36px' icon={<LinkedInLogo color='#fff' />} />
                                <LogoLink href='https://www.tiktok.com/@amark.pro' width='36px' height='36px' icon={<TikTokLogo color='#fff' />} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            <Ball
                width='400px'
                height='400px'
                bottom='10%'
                right='-5%'
                color='#00BC78'
            />

        </section>
    );
};

export default ContactFormSection;