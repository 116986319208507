const BehanceHollowLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path d="M1 16V4H5.5C7.15685 4 8.5 5.34315 8.5 7C8.5 8.65685 7.15685 10 5.5 10C7.15685 10 8.5 11.3431 8.5 13C8.5 14.6569 7.15685 16 5.5 16H1" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 10H5.5" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 11H19C19 9.067 17.433 7.5 15.5 7.5C13.567 7.5 12 9.067 12 11V13C12.2119 14.5377 13.4111 15.7521 14.946 15.9833C16.4809 16.2144 17.9847 15.4071 18.64 14" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14 4H17" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default BehanceHollowLogo;