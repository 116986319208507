import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import WhyUsCard from "../UI/block/card/WhyUsCard";

import './WhyUsSection.sass';

const WhyUsSection = (props) => {
    const { t } = useTranslation();

    const whyUsCardHeaders = [
        (<div dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason1.title")}} />),
        (<div dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason2.title")}} />),
        (<div dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason3.title")}} />),
        (<div dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason4.title")}} />),
        (<div dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason5.title")}} />)
    ];

    return (
        <section className='why-us d-flex justify-content-center' id='whyUs' ref={props.sectionRef}>
            <div className='rotated-block'>
                <Container>
                    <SectionHeader header={t("whyUs.header.title")}>
                        {t("whyUs.header.description")}
                    </SectionHeader>
                    <div className='why-us__grid'>
                        <WhyUsCard header={whyUsCardHeaders[0]} green>
                            <p dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason1.description")}} />
                        </WhyUsCard>
                        <WhyUsCard header={whyUsCardHeaders[1]} blue>
                            <p dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason2.description")}} />
                        </WhyUsCard>
                        <WhyUsCard header={whyUsCardHeaders[2]} pink>
                            <p dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason3.description")}} />
                        </WhyUsCard>
                        <WhyUsCard header={whyUsCardHeaders[3]} orange>
                            <p dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason4.description")}} />
                        </WhyUsCard>
                        <WhyUsCard header={whyUsCardHeaders[4]} green>
                            <p dangerouslySetInnerHTML={{__html: t("whyUs.reasons.reason5.description")}} />
                        </WhyUsCard>
                    </div>
                </Container>
            </div>
        </section>
    );
};

export default WhyUsSection;