import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import { gtag, install, initDataLayer } from 'ga-gtag';
import Cookies from 'js-cookie'
import {Link} from "react-router-dom";

import Button from "../Buttons/Button";

import './CookiesBlock.sass';

const gtagToken = 'G-GJBZTJCD3T';
const CONSENT_GIVEN = 'consent_given';
const PREFERENCES_COOKIE = 'preferences_consent';
const STATISTICS_COOKIE = 'statistics_consent';
const MARKETING_COOKIE = 'marketing_consent';

const CookiesBlock = () => {
    const { t } = useTranslation();

    const [consentGiven, setConsentGiven] = useState(Cookies.get(CONSENT_GIVEN));
    const [preferencesCookies, setPreferencesCookies] = useState(
        Cookies.get(CONSENT_GIVEN) ? Cookies.get(PREFERENCES_COOKIE) : true
    );
    const [statisticsCookies, setStatisticsCookies] = useState(
        Cookies.get(CONSENT_GIVEN) ? Cookies.get(STATISTICS_COOKIE) : true
    );
    const [marketingCookies, setMarketingCookies] = useState(
        Cookies.get(CONSENT_GIVEN) ? Cookies.get(MARKETING_COOKIE) : true
    );

    useEffect(() => {
        initDataLayer();

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });

        install(gtagToken);
    }, []);

    const updateConsent = () => {
        initDataLayer();

        if (Cookies.get(PREFERENCES_COOKIE)) {
            gtag('consent', 'update', {
                'ad_personalization': 'granted',
                'ad_user_data': 'granted'
            });
        }

        if (Cookies.get(STATISTICS_COOKIE)) {
            gtag('consent', 'update', {
                'analytics_storage': 'granted'
            });
        }

        if (Cookies.get(MARKETING_COOKIE)) {
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted'
            });
        }

        install(gtagToken);
    };

    if (Cookies.get(CONSENT_GIVEN)) {
        updateConsent();
        return null;
    }

    const acceptButtonHandle = () => {
        Cookies.set(CONSENT_GIVEN, true, { expires: 365 });

        setConsentGiven(true);

        if (preferencesCookies) {
            Cookies.set(PREFERENCES_COOKIE, true, { expires: 365 });
        } else {
            Cookies.remove(PREFERENCES_COOKIE);
        }

        if (statisticsCookies) {
            Cookies.set(STATISTICS_COOKIE, true, { expires: 365 });
        } else {
            Cookies.remove(STATISTICS_COOKIE);
        }

        if (marketingCookies) {
            Cookies.set(MARKETING_COOKIE, true, { expires: 365 });
        } else {
            Cookies.remove(MARKETING_COOKIE);
        }

        updateConsent();
    };

    return consentGiven ? null : (
        <div className='cookies'>
            <div className='cookies-text'>
                {t("cookies.description")} <Link to={"/policy#cookies"} className="link">{t("cookies.policyLink")}</Link>.
            </div>
            <div className='d-flex align-items-center flex-wrap checkbox-container'>
                <div>
                    <label><input type='checkbox' disabled checked /> {t("cookies.necessaryCheckbox")}</label>
                </div>
                <div>
                    <label>
                        <input
                            type='checkbox'
                            checked={preferencesCookies}
                            onChange={() => setPreferencesCookies(prev => !prev)}
                        />
                        {t("cookies.preferencesCheckbox")}
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type='checkbox'
                            checked={statisticsCookies}
                            onChange={() => setStatisticsCookies(prev => !prev)}
                        />
                        {t("cookies.statisticsCheckbox")}
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type='checkbox'
                            checked={marketingCookies}
                            onChange={() => setMarketingCookies(prev => !prev)}
                        />
                        {t("cookies.marketingCheckbox")}
                    </label>
                </div>
            </div>
            <Button className='cookies__button' onClick={acceptButtonHandle}>{t("cookies.actionButton")}</Button>
        </div>
    );
};

export default CookiesBlock;