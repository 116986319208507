const InstagramHollowLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <rect x="1" y="1" width="18" height="18" rx="4" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="10" cy="10" r="3.375" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.0625 4.93743V4.93855" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default InstagramHollowLogo;