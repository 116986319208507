import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import Line from "../Line";

import './AboutUsSection.sass';
import about from '../../assets/video/about-us.mp4';

const AboutUsSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='about-us' id='aboutUs' ref={props.sectionRef}>
            <Container full>
                <div className='about-grid'>
                    <div className='padding'>
                        <Container>
                            <SectionHeader header={t("aboutUs.header.title")}>
                                {t("aboutUs.header.description")}
                            </SectionHeader>
                            <p className='text-light about-us__text' dangerouslySetInnerHTML={{__html: t("aboutUs.aboutText")}} />
                            <Line sizeClass='line-container' />
                            <p className='text-dark'>
                                {t("aboutUs.sign.name")} <br />
                                {t("aboutUs.sign.position")}
                            </p>
                        </Container>
                    </div>
                    <div>
                        <video autoPlay loop muted playsInline>
                            <source src={about} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default AboutUsSection;