import './Ball.sass';

const Ball = (props) => {
    const style = {
        top: props.top,
        left: props.left,
        right: props.right,
        bottom: props.bottom,
        background: props.color,
        width: props.width,
        height: props.height
    };

    const alternate = props.alternate ? ' alternate' : '';

    return (
        <div className={'ball' + alternate} style={style} />
    );
};

export default Ball;