const AmarkLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 619.49 313.05" width='100%' height='100%'>
            <g id="Transparent_green" fill='#fff'>
                <path d="M163.16,273.45v37.2a2.39,2.39,0,0,0,2.4,2.4h102.8a2.4,2.4,0,0,0,2.4-2.4h0V151.59l-97,97.2A33.91,33.91,0,0,0,163.16,273.45Z"/>
                <path d="M243.16,4.77,10.59,237.47A33.87,33.87,0,0,0,0,262.07v49.75A1.19,1.19,0,0,0,1.2,313H62.45c37.16-33.18,163.7-162.91,200.86-200.75A26.08,26.08,0,0,0,270.79,94V16.21A16.18,16.18,0,0,0,243.16,4.77Z"/>
                <path d="M511.86,274v38.73H619.49V152.18l-97,97.21A33.89,33.89,0,0,0,511.86,274Z"/>
                <polygon points="576.58 0 464.24 112.33 351.92 0 306.48 0 306.48 313.01 384.1 313.01 384.1 140.73 485.07 241.7 619.49 107.29 619.49 0 576.58 0"/>
            </g>
        </svg>
    );
};

export default AmarkLogo;