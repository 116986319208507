import './ProjectCard.sass';

const ProjectCard = (props) => {
    const cardClickHandler = () => {
        window.open(props.url, '_blank').focus();
    };

    let cursorClass = 'pointer';
    let onClickProp = cardClickHandler;
    if (props.url === undefined) {
        cursorClass = 'not-allowed';
        onClickProp = null;
    }

    return (
        <article className={'project-card__container ' + cursorClass} onClick={onClickProp}>
            <div className='project-card'>
                <img src={props.image} alt='' />
                <div className='padding'>
                    <h2>{props.header}</h2>
                    <p>{props.description}</p>
                </div>
            </div>
            <div className='mask maskLeft' />
            <div className='mask maskRight' />
        </article>
    );
};

export default ProjectCard;