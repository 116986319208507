import './WhyUsCard.sass';

const WhyUsCard = (props) => {
    let color = '';
    if (props.green) color = 'green';
    else if (props.blue) color = 'blue';
    else if (props.pink) color = 'pink';
    else if (props.orange) color = 'orange';

    return (
        <article className={'why-us-card ' + color}>
            <h2>{props.header}</h2>
            <div>
                {props.children}
            </div>
            <p className='icon d-flex justify-content-end'>”</p>
        </article>
    );
};

export default WhyUsCard;