import './Input.sass';

const Textarea = (props) => {
    let errorClass = '';
    if (props.isError) errorClass = 'error';

    return (
        <div className='input-container'>
            <label htmlFor={props.name}>{props.label}</label>
            <textarea
                className={errorClass}
                name={props.name}
                id={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
            />
        </div>
    );
};

export default Textarea;