import {Fragment} from "react";
import useElementOnScreen from "../hooks/use-element-on-screen";

import HeroSection from "../сomponents/section/HeroSection";
import WhatWeDoSection from "../сomponents/section/WhatWeDoSection";
import OurProjectsSection from "../сomponents/section/OurProjectsSection";
import AboutUsSection from "../сomponents/section/AboutUsSection";
import WhyUsSection from "../сomponents/section/WhyUsSection";
import HowWeWorkSection from "../сomponents/section/HowWeWorkSection";
import HappyClientsSection from "../сomponents/section/HappyClientsSection";
import ContactFormSection from "../сomponents/section/ContactFormSection";
import Header from "../сomponents/section/Header/Header";

const Home = () => {
    const intersectionOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    };

    const [HeroSectionRef, HeroSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [WhatWeDoSectionRef, WhatWeDoSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [OurProjectsSectionRef, OurProjectsSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [AboutUsSectionRef, AboutUsSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [WhyUsSectionRef, WhyUsSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [HowWeWorkSectionRef, HowWeWorkSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [HappyClientsSectionRef, HappyClientsSectionIsVisible] = useElementOnScreen(intersectionOptions);
    const [ContactFormSectionRef, ContactFormSectionIsVisible] = useElementOnScreen(intersectionOptions);

    const visibility = {
        hero: HeroSectionIsVisible,
        whatWeDo: WhatWeDoSectionIsVisible,
        ourProjects: OurProjectsSectionIsVisible,
        aboutUs: AboutUsSectionIsVisible,
        whyUs: WhyUsSectionIsVisible,
        howWeWork: HowWeWorkSectionIsVisible,
        happyClients: HappyClientsSectionIsVisible,
        contactForm: ContactFormSectionIsVisible
    };

    return (
        <Fragment>
            <Header visible={visibility} home={true} />
            <main>
                <HeroSection sectionRef={HeroSectionRef} isVisible={HeroSectionIsVisible} />
                <WhatWeDoSection sectionRef={WhatWeDoSectionRef} />
                <OurProjectsSection sectionRef={OurProjectsSectionRef} />
                <AboutUsSection sectionRef={AboutUsSectionRef} />
                <WhyUsSection sectionRef={WhyUsSectionRef} />
                <HowWeWorkSection sectionRef={HowWeWorkSectionRef} />
                <HappyClientsSection sectionRef={HappyClientsSectionRef} />
                <ContactFormSection sectionRef={ContactFormSectionRef} />
            </main>
        </Fragment>

    );
};

export default Home;