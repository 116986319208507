import './Hashtag.sass';

const Hashtag = (props) => {
    return (
        <div className='hashtag'>
            {props.text}
        </div>
    );
};

export default Hashtag;