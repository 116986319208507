import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import Header from "../сomponents/section/Header/Header";
import Container from "../сomponents/UI/Container";
import PageHeader from "../сomponents/UI/header/PageHeader";
import PolicyBlock from "../сomponents/UI/block/PolicyBlock";
import Line from "../сomponents/Line";

import './PolicyPage.sass';

const PolicyPage = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Header />
            <main style={{margin: '5rem 0'}}>
                <section>
                    <Container>
                        <PageHeader>
                            {t("policy.pageName")}
                        </PageHeader>
                        <h2>{t("policy.generalTermsSection")}</h2>
                        <p>{t("policy.lastUpdate")} 19.08.2024</p>
                        <p>{t("policy.introduction")}</p>
                        <PolicyBlock
                            headerText={[
                                t("policy.generalTerms.header"),
                                t("policy.useOfSite.header"),
                                t("policy.personalDataPrivacy.header"),
                                t("policy.googleAnalytics4.header"),
                                t("policy.limitationOfLiability.header"),
                                t("policy.amendments.header")
                            ]}
                            paragraphs={[
                                [t("policy.generalTerms.par1"), t("policy.generalTerms.par2")],
                                [t("policy.useOfSite.par1"), t("policy.useOfSite.par2")],
                                [t("policy.personalDataPrivacy.par1"), t("policy.personalDataPrivacy.par2")],
                                [t("policy.googleAnalytics4.par1"), t("policy.googleAnalytics4.par2")],
                                [t("policy.limitationOfLiability.par1")],
                                [t("policy.amendments.par1")]
                            ]}
                        />
                        <h3>{t("policy.contactInfo.header")}</h3>
                        <p>{t("policy.contactInfo.par1")} <a className={'link'}
                                                             href={"mailto:m.advancedmarketing@gmail.com"}>m.advancedmarketing@gmail.com</a>
                        </p>
                    </Container>
                </section>
                <section>
                    <Container>
                        <Line center sizeClass={'line-size'} />
                        <div id={'cookies'} />
                        <h2>{t("policy.cookiesSection")}</h2>
                        <p>{t("policy.lastUpdate")} 19.08.2024</p>
                        <PolicyBlock
                            headerText={[
                                t("policy.whatAreCookies.header"),
                                t("policy.howWeUseCookies.header"),
                                t("policy.googleAnalyticsCookies.header"),
                                t("policy.managingCookies.header")
                            ]}
                            paragraphs={[
                                [t("policy.whatAreCookies.par1")],
                                [t("policy.howWeUseCookies.par1")],
                                [t("policy.googleAnalyticsCookies.par1"), t("policy.googleAnalyticsCookies.par2")],
                                [t("policy.managingCookies.par1"), t("policy.managingCookies.par2")]
                            ]}
                        />
                        <h3>{t("policy.contactInfo.header")}</h3>
                        <p>{t("policy.contactInfo.par1")} <a className={'link'} href={"mailto:m.advancedmarketing@gmail.com"}>m.advancedmarketing@gmail.com</a></p>
                    </Container>
                </section>
            </main>
        </Fragment>
    );
};

export default PolicyPage;