import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import BriefingIcon from "../../assets/icons/how-we-work/BriefingIcon";
import Ipad from "../../assets/icons/how-we-work/Ipad";
import Process from "../../assets/icons/how-we-work/Process";
import SuccessIcon from "../../assets/icons/SuccessIcon";

import './HowWeWorkSection.sass';

const HowWeWorkSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='how-work' id='howWeWork' ref={props.sectionRef}>
            <Container>
                <SectionHeader header={t("howWeWork.header.title")}>
                    {t("howWeWork.header.description")}
                </SectionHeader>
            </Container>

            <Container full>
                <div className='how-work__grid'>
                    <div className='grid-item'>
                        <span className='number'>01</span>
                        <div className='text' dangerouslySetInnerHTML={{__html: t("howWeWork.steps.step1")}} />
                        <div className='icon'>
                            <BriefingIcon />
                        </div>
                    </div>
                    <div className='grid-item'>
                        <span className='number' style={{top: '35%'}}>02</span>
                        <div className='text' dangerouslySetInnerHTML={{__html: t("howWeWork.steps.step2")}} />
                    </div>
                    <div className='grid-item'>
                        <span className='number' style={{right: '80px'}}>03</span>
                        <div className='text' dangerouslySetInnerHTML={{__html: t("howWeWork.steps.step3")}} />
                        <div className='icon' style={{width: '240px', height: '320px', right: '-75px', bottom: '20px'}}>
                            <Ipad />
                        </div>
                    </div>
                    <div className='grid-item'>
                        <span className='number'>04</span>
                        <div className='text' style={{top: '30%', left: '65%'}} dangerouslySetInnerHTML={{__html: t("howWeWork.steps.step4")}} />
                        <div className='icon' style={{width: '370px', height: '340px', right: '30%', bottom: '30px'}}>
                            <Process />
                        </div>
                    </div>
                    <div className='grid-item'>
                        <span className='number' style={{top: '80%'}}>05</span>
                        <div className='d-flex justify-content-center align-items-center' style={{height: '100%'}}>
                            <div className='text-center release-block'>
                                <p>{t("howWeWork.steps.step5.line1")}</p>
                                <div style={{width: '90px', height: '70px', margin: '67px auto 35px auto'}}>
                                    <SuccessIcon />
                                </div>
                                <p>{t("howWeWork.steps.step5.line2")}</p>
                            </div>
                        </div>
                    </div>
                    <div className='grid-item'>
                        <div className='text' style={{top: '30%', left: '20px'}} dangerouslySetInnerHTML={{__html: t("howWeWork.steps.step6")}} />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default HowWeWorkSection;