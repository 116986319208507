import './PageHeader.sass';
import Line from "../../Line";

const PageHeader = (props) => {
    return (
        <div className='page-header d-flex justify-content-center'>
            <div className='page-header__container d-flex align-items-center flex-column text-center'>
                <h1>{props.children}</h1>
                <Line sizeClass='line-size' />
            </div>
        </div>
    );
};

export default PageHeader;