const BriefingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="154" height="203" viewBox="0 0 154 203" fill="none">
            <g filter="url(#filter0_b_1091_713)">
                <path d="M49.5291 0H153.529V166H49.5291V0Z" fill="#888888" fillOpacity="0.17"/>
            </g>
            <g filter="url(#filter1_b_1091_713)">
                <path d="M0 37H110.055V203H0V37Z" fill="white" fillOpacity="0.05"/>
            </g>
            <defs>
                <filter id="filter0_b_1091_713" x="25.5291" y="-24" width="152" height="214" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="12"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1091_713"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1091_713" result="shape"/>
                </filter>
                <filter id="filter1_b_1091_713" x="-24" y="13" width="158.055" height="214" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="12"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1091_713"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1091_713" result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default BriefingIcon;