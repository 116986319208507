import {useTranslation} from "react-i18next";

import Container from "../UI/Container";
import SectionHeader from "../UI/header/SectionHeader";
import ReviewCard from "../UI/block/card/ReviewCard";

import review1 from '../../assets/img/reviews/review1.jpg';
import review2 from '../../assets/img/reviews/review2.png';
import review4 from '../../assets/img/reviews/review4.png';
import review5 from '../../assets/img/reviews/review5.png';
import review7 from '../../assets/img/reviews/review7.jpg';
import './HappyClientsSection.sass';

const HappyClientsSection = (props) => {
    const { t } = useTranslation();

    return (
        <section className='happy-clients' id='happyClients' ref={props.sectionRef}>
            <Container>
                <SectionHeader header={t("happyClients.header.title")}>
                    {t("happyClients.header.description")}
                </SectionHeader>
                <div className='happy-clients__grid'>
                    <ReviewCard
                        name={t("happyClients.reviews.review1.name")}
                        occupation={t("happyClients.reviews.review1.occupation")}
                        image={review1}
                    >
                        {t("happyClients.reviews.review1.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review2.name")}
                        occupation={t("happyClients.reviews.review2.occupation")}
                        image={review2}
                    >
                        {t("happyClients.reviews.review2.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review3.name")}
                        occupation={t("happyClients.reviews.review3.occupation")}
                        color='linear-gradient(150.05deg, #3B7E35 24.16%, #AFAFAF 91.18%)'
                    >
                        {t("happyClients.reviews.review3.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review4.name")}
                        occupation={t("happyClients.reviews.review4.occupation")}
                        image={review4}
                    >
                        {t("happyClients.reviews.review4.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review5.name")}
                        occupation={t("happyClients.reviews.review5.occupation")}
                        image={review5}
                    >
                        {t("happyClients.reviews.review5.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review6.name")}
                        occupation={t("happyClients.reviews.review6.occupation")}
                        color='#C4C4C4'
                    >
                        {t("happyClients.reviews.review6.text")}
                    </ReviewCard>
                    <ReviewCard
                        name={t("happyClients.reviews.review7.name")}
                        occupation={t("happyClients.reviews.review7.occupation")}
                        image={review7}
                    >
                        {t("happyClients.reviews.review7.text")}
                    </ReviewCard>
                </div>
            </Container>
        </section>
    );
};

export default HappyClientsSection;